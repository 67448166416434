// src/pages/LandingPage.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ThemeCard from '../components/ThemeCard';
import AuthorSection from '../components/AuthorSection';
import PreorderSlider from '../components/PreorderSlider';
import ExcerptSection from '../components/ExcerptSection';
import MediaPlayer from '../components/MediaPlayer';
import SocialLinks from '../components/SocialLinks';

import { Book, Brain, Users, Globe } from 'lucide-react';

function LandingPage() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-green-50 text-gray-800">
      <Header />
      
      <main className="container mx-auto px-4 sm:px-6 pt-2 sm:pt-4 pb-8 sm:pb-12">
        {/* Hero Section */}
        <section className="mb-8 sm:mb-12 max-w-6xl mx-auto">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 leading-tight text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-gray-600 py-2">
            Economic System for the AI Age That Empowers All of Us
          </h1>
          <div className="flex flex-col lg:flex-row items-stretch justify-between gap-6 sm:gap-8">
            <div className="w-full lg:w-1/3 flex items-center justify-center">
              <img 
                src="/eow_book.png" 
                alt="Economy of Wisdom Book" 
                className="w-full h-auto max-h-[500px] object-contain"
              />
            </div>
            <div className="w-full lg:w-1/3 flex items-center justify-center">
              <PreorderSlider />
            </div>
            <div className="w-full lg:w-1/3 hidden lg:flex items-center justify-center">
              <img 
                src="/open_book.png" 
                alt="Open Economy of Wisdom Book" 
                className="w-full h-auto max-h-[500px] object-contain"
              />
            </div>
          </div>
          <div className="mt-8">
            <SocialLinks />
          </div>
        </section>


        {/* Excerpt Section */}
        <section id="about" className="mb-16">
          <ExcerptSection />
        </section>

        {/* Themes Section */}
        <section id="themes" className="mb-16">
          <h2 className="text-2xl sm:text-3xl font-semibold mb-6 text-center relative">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-gray-600">
              Economy of Wisdom Themes
            </span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <ThemeCard icon={Brain} title="Human Unfulfillment" description="Rethink the existing system to achieve a balance of human material, intellectual, and emotional needs" />
            <ThemeCard icon={Book} title="AI Misalignment" description="Discover the risks to humanity from the existing approach to AI development driven by mindless competition" />
            <ThemeCard icon={Users} title="Social Identity" description="Consider whether your social identity helps you thrive or makes you vulnerable to exploitation" />
            <ThemeCard icon={Globe} title="Global Perspective" description="Adopt a global perspective on the monumental technological and societal transformation underway" />
          </div>
        </section>

        {/* Faq Section */}
        <section id="faq" className="mb-16">
          <h2 className="text-2xl sm:text-3xl font-semibold mb-6 text-center relative">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-gray-600">
              Frequently Asked Questions
            </span>
          </h2>
          <div className="bg-white p-8 rounded-lg shadow-lg relative overflow-hidden">
            <div className="absolute top-0 right-0 w-16 h-16 bg-gray-200 transform rotate-45 translate-x-8 -translate-y-8"></div>
            <div className="relative z-10">
              <p className="text-lg mb-4">
                <b>Who is this book for?</b>
              </p>
              <p className="text-lg mb-4">
                This book is for <b>knowledge workers</b> looking to understand <b>human unfulfillment</b> and <b>AI misalignment</b>, who might be concerned by rapid AI proliferation and automation trends and what those trends indicate.
              </p>
              <p className="text-lg mb-4">
                Some chapters of this book are meant for <b>technology executives</b> looking to make an impact in their organization that positively affects the experience with AI of all stakeholders, not only investors but also employees, business partners, customers, and otherwise concerned citizens.  You will understand the scope of the human unfulfillment crisis that might translate into engagement issues in your organizations and the automation trends on every employee’s mind.
              </p>
              <p className="text-lg mb-4"> 
                Other chapters are for socially conscious <b>innovators</b> and <b>investors</b> seeking to leverage AI to positively impact or invest in companies that empower humanity instead of creating technologies of dominance.  You will understand the problem of AI alignment and bias and later reimagine our capital system to align with human values while preserving prosperity.
              </p>
              <p className="text-lg mb-4"> 
                All are for <b>researchers</b> and <b>students</b> to find empirical evidence to prove or disprove the outlined thoughts and ideas.  Some sections are underresearched and might give ideas for your thesis.
              </p>
              <p className="text-lg mb-4"> 
                Lastly, this book calls for <b>political leaders</b> to choose love and empowerment over fear and come together to cooperate on the global stage rather than engage in identity politics and mindless competition to create technologies of progressively greater power.  The book defines identity politics and shares ideas on unifying instead of dividing. 
              </p>
              <p className="text-lg mb-4"> 
                It is a call for <b>all</b> to become <b>conscious agents</b> of positive change and start an all-inclusive, constructive global discussion on distributing technological power so that it empowers all of us.
              </p>
              <p className="text-lg mb-4">
                <b>What is the book about?</b>
              </p>
              <p className="text-lg mb-4">
                Listen to AI podcast summarizing chapter 1.
                <MediaPlayer 
                  src="/Economy-of-Wisdom-Chapter-1.mp4" 
                  title="Losing Self-Fulfillment and Our Power - Economy of Wisdom Chapter 1 - AI Podcast"
                />
              </p>
              <p className="text-lg mb-4">
                <b>Why should I preorder?</b>
              </p>
              <p className="text-lg mb-4">
                You will get access to individual chapters as they are written and the opportunity to provide feedback that will shape the final version of the book.  You will also get a discount on the final price of the book and a chance to be listed in the book as a contributor.  Lastly, you will be supporting the author in his mission to ensure that AI empowers all of us.
              </p>
              <p className="text-lg mb-4">
                <b>I care about these issues and want to collaborate.  How can I reach the author?</b>
              </p>
              <p className="text-lg mb-4">
                The underground copies of the draft first chapter are being distributed to the author's friends and colleagues globally.  To collaborate on this book, reach out to Sev 
                at <a href="mailto:economyofwisdom@gmail.com">economyofwisdom@gmail.com</a> with a pre-purchase receipt.  I am grateful and excited to work with you.
              </p>
              <p className="text-lg mb-4">
                <b>When is the second chapter released?</b>
              </p>
              <p className="text-lg mb-4">
                Early release of Chapter 2 becomes available in <b>November, 2024</b> for those who preordered.
              </p>
              <p className="text-lg mb-4">
                <b>When is the whole book released?</b>
              </p>
              <p className="text-lg mb-4">
                TBD 2025
              </p>
              </div>
            <div className="absolute bottom-4 right-4 text-gray-400">2</div>
          </div>
        </section>

        <AuthorSection />
      </main>

      <Footer />
    </div>
  );
}

export default LandingPage;
